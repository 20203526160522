<template>
    <modal :show.sync="openPolicy" style="text-align: justify; z-index: 9999999999999999999999">
        <h3 slot="header" class="modal-title text-left" id="modal-title-default">{{$t('policy.title')}}</h3>
        <privacy-policy></privacy-policy>
        <template slot="footer">
            <base-button type="link" class="ml-auto" @click="$emit('closePolicy');">
                {{$t('global.close')}}
            </base-button>
        </template>
    </modal>
</template>

<script>
    import PrivacyPolicy from '../components/PrivacyPolicy';

    export default {
        name: 'PrivacyPolicyModal',
        components: {
            PrivacyPolicy
        },
        props: {
            open: { default: false }
        },
        data() {
            return {
                openPolicy: false
            }
        },
        watch: {
            open() {
                this.openPolicy = this.open;
            },
            openPolicy() {
                if (this.openPolicy == false) {
                    this.$emit('closePolicy');
                }
            }
        }
    }
</script>
