<template>
    <card type="secondary" shadow
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0"
          style="text-align: center;"
          id="register">
        <template>
            <base-alert v-if="alert" type="warning" id="alert">
                <span class="alert-inner--text">{{alert_message}}</span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alert = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>
            <form role="form">
                <div v-if="message" class="pb-4">
                    <span class="lead">{{message}}<!--<a v-if="type == 'requestor'" href="javascript:void(0)" @click="$gtag.event('learn'); $emit('learn');">{{$t('global.learn')}}.</a>--></span>
                </div>

                <b-form-group v-if="showType">
                    <b-form-radio-group class="justify-content-md-center" v-model="model.type" style="font-weight: 700;">
                        <b-form-radio value="requestor">{{source == 'page' ? $t('requestor') : $t('register.requestor')}}</b-form-radio>
                        <b-form-radio value="realtor">{{source == 'page' ? $t('realtor') : $t('register.realtor')}}</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>

                <base-input v-for="input in inputs.profile"
                            alternative
                            :id="input.name"
                            :key="input.name"
                            :filter="input.filter"
                            :type="input.type"
                            :class="input.class"
                            :placeholder="input.placeholder"
                            :addon-left-icon="input.addon"
                            :required="input.required"
                            v-model="model[input.name]"
                            :valid="input.valid"
                            @blur="checkValidity(input.name)">
                </base-input>

                <base-input :numberholder="$t('register.number')"
                            alternative
                            type="text"
                            :telInput="true"
                            @tel-input="onInput"
                            :valid="telValidity"
                            @blur="checkValidity('tel')"
                            autocomplete="off">
                </base-input>

                <base-input v-for="input in inputs.credential"
                            alternative
                            :key="input.name"
                            :filter="input.filter"
                            :type="input.type"
                            :class="input.class"
                            :placeholder="input.placeholder"
                            :addon-left-icon="input.addon"
                            :required="input.required"
                            v-model="model[input.name]"
                            :valid="passwordMatch"
                            @blur="input.method"
                            showSuccess="">
                </base-input>

                <!--<div v-show="model.type == 'realtor'">
                    <div class="form-group input-group input-group-alternative">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="ni ni-pin-3"></i>
                            </span>
                        </div>-->
                        <!--<input type="text"
                               ref="Autocomplete"
                               id="autocomplete"
                               :placeholder="$t('register.city')"
                               class="form-control"
                               @blur="wipeOnBlur($event)"
                               required
                               autocomplete="off"
                               aria-describedby="addon-right addon-left" />
                    </div>-->

                    <!--Will hide for now (40 realtors later). The less input fields the better.-->
                    <!--<b-form-group :label="$t('register.agency.question')">
                        <b-form-radio-group v-model="model.agency.associated">
                            <b-form-radio value="1">{{$t('register.agency.yes')}}</b-form-radio>
                            <b-form-radio value="2">{{$t('register.agency.no')}}</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>-->

                    <!--<div v-if="model.agency.associated == '1'">
                        <base-input v-for="(input, n) in inputs.agency"
                                    alternative
                                    :key="input.name + n"
                                    :type="input.type"
                                    :class="input.class"
                                    :placeholder="input.placeholder"
                                    :addon-left-icon="input.addon"
                                    :required="true"
                                    v-model="model[input.name][input.subname]"
                                    :valid="input.valid"
                                    @blur="checkValidity(input.subname)">
                        </base-input>
                    </div>-->
                <!--</div>-->

                <base-checkbox v-model="model.policy">
                    {{$t('register.policy.agreement')}}
                    <a href="javascript:void(0)" @click="$gtag.event('read_policy'); policy = true">{{$t('register.policy.link')}}</a>
                </base-checkbox>
                <div class="text-center">
                    <base-button type="primary" class="my-4" @click="submitModel()">{{$t('register.submit')}}</base-button>
                </div>
            </form>
        </template>
        <b-popover v-if="showPopover" target="email" triggers="hover" :placement="desktop ? 'left' : 'top'">
            {{$t('register.popover.email', {type: popover_type})}}
        </b-popover>
        <privacy-policy-modal :open="policy" @closePolicy="policy = false"></privacy-policy-modal>
    </card>
</template>

<script>
    //import gmapsInit from '../utils/gmaps';
    import { BFormGroup, BFormRadio, BFormRadioGroup, BPopover } from 'bootstrap-vue';
    import $ from 'jquery';
    import PrivacyPolicyModal from '../components/PrivacyPolicyModal'

    export default {
        name: 'RegisterCard',
        components: {
            BFormRadio,
            BFormGroup,
            BFormRadioGroup,
            BPopover,
            PrivacyPolicyModal
        },
        props: {
            type: { default: 'requestor' },
            source: { default: 'page' },
            showType: { default: true },
            message: { default: '' },
            login: { default: false },
            showPopover: {default: true}
        },
        data() {
            return {
                model: {
                    type: this.type,
                    name: '',
                    email: '',
                    number: {},
                    country: {},
                    password: '',
                    verification: '',
                    policy: false,
                    city: {
                        location: 'S�o Paulo, SP, Brazil',
                        place: '(-23.5505199, -46.6333094)',
                        coordinate: {
                            lat: -23.5505199,
                            lng: -46.6333094
                        },
                    },
                    agency: {
                        associated: '2',
                        trade: '',
                        site: '',
                    },
                },
                inputs: {
                    profile: [
                        {
                            class: "mb-3",
                            placeholder: this.$t('register.name'),
                            addon: "ni ni-single-02",
                            required: true,
                            type: "text",
                            name: 'name',
                            valid: null,
                        },
                        {
                            class: "mb-3",
                            placeholder: "E-mail",
                            addon: "ni ni-email-83",
                            required: true,
                            name: 'email',
                            type: "email",
                            valid: null,
                        },
                    ],
                    credential: [
                        {
                            class: "mb-3",
                            placeholder: this.$t('register.password'),
                            addon: "ni ni-lock-circle-open",
                            required: true,
                            name: 'password',
                            type: "password",
                            method: this.verifyPassword
                        },
                        {
                            class: "mb-3",
                            placeholder: this.$t('register.confirmation'),
                            addon: "ni ni-lock-circle-open",
                            required: true,
                            name: 'verification',
                            type: "password",
                            method: this.verifyPassword
                        },
                    ],
                    agency: [
                        {
                            class: "mb-3",
                            placeholder: this.$t('register.agency.name'),
                            addon: "ni ni-briefcase-24",
                            required: true,
                            type: "text",
                            name: 'agency',
                            subname: 'trade',
                            valid: null,
                        },
                        {
                            class: "mb-3",
                            placeholder: this.$t('register.agency.website'),
                            addon: "ni ni-world-2",
                            required: true,
                            type: "url",
                            name: 'agency',
                            subname: 'site',
                            valid: null,
                        }
                    ]
                },
                alert: false,
                alert_message: '',
                passwordMatch: null,
                telValidity: null,
                submitTry: 1,
                desktop: screen.width > 700,
                popover_type: this.$tc('global.type.' + (this.type == 'realtor' ? 'requestor' : 'realtor'), 2),
                policy: false,
            }
        },
        watch: {
            type: function () {
                this.model.type = this.type;
                this.$emit('type-change', this.type);
            },
            'model.type'() {
                this.$emit('type-change', this.model.type);
                this.popover_type = this.$tc('global.type.' + (this.model.type == 'realtor' ? 'requestor' : 'realtor'), 2)
            },
            deep: true,
        },
        methods: {
            checkValidity(field) {
                if (field == 'name') {
                    if (this.model.name === '') {
                        this.inputs.profile[0].valid = false;
                    } else if ((this.model.name.length < 3) && !(this.model.name.includes(' '))) {
                        this.inputs.profile[0].valid = false;
                    } else {
                        this.inputs.profile[0].valid = true;
                    }
                }
                if (field == 'email') {
                    if (this.model.email === '') {
                        this.inputs.profile[1].valid = false;
                    } else if (this.model.email.includes('@') && this.model.email.includes('.') && this.model.email.length > 5) {
                        this.inputs.profile[1].valid = true;
                    } else {
                        this.inputs.profile[1].valid = false;
                    }
                }
                if (field == 'tel') {
                    if (this.model.number === {}) {
                        this.telValidity = false;
                    }
                }
                if (field == 'trade') {
                    if (this.model.agency.trade.length < 2) {
                        this.inputs.agency[0].valid = false;
                    } else {
                        this.inputs.agency[0].valid = true;
                    }
                }
                if (field == 'site') {
                    if (this.model.agency.site.includes('.') && this.model.agency.site.length > 10 && (this.model.agency.site.includes('http://') || this.model.agency.site.includes('https://') || this.model.agency.site.includes('www.'))) {
                        this.inputs.agency[1].valid = true;
                    } else {
                        this.inputs.agency[1].valid = false;
                    }
                }
            },
            verifyPassword() {
                if (!(this.model.password === undefined)) {
                    if ((this.model.password == this.model.verification) && this.model.password.length > 5) {
                        this.passwordMatch = true;
                    } else {
                        this.passwordMatch = false;
                    }
                } else if (!(this.model.verification === undefined)) {
                    this.passwordMatch = false;
                }
            },
            messageAlert(message) {
                this.alert = true;
                this.alert_message = this.$t(message);
                this.$scrollTo('#register')
            },
            updateInput(key, subkey, value) {                
                if (subkey) {
                    this.model[key][subkey] = value;
                } else {
                    this.model[key] = value;
                }
            },
            wipeOnBlur(event) {
                event.target.value = '';
            },
            onInput({ number, isValid, country }) {
                //this.number_is_valid = isValid;
                if (isValid) {
                    this.telValidity = true;
                    this.model.number = number;
                    this.model.country = country;
                } else {
                    this.telValidity = false;
                }
            },
            updateStorage(data) {
                this.$gtag.set({ 'user_id': data._id });
                this.$gtag.event('login', { method: 'request' })
                this.$store.replaceState({ ...this.$store.state, ...data });
                this.$store.dispatch('updateLoginStatus', true);
                this.$store.dispatch('updateType', data.type);
            },

            emit(event) {
                this.$emit(event);
            },

            submitModel() {
                var messageAlert = this.messageAlert;
                var gtag = this.$gtag;
                var source = this.source;
                var submitTry = this.submitTry;
                var user_type = this.model.type;
                var router = this.$router;
                var store = this.$store;
                var login = this.login;
                var updateStorage = this.updateStorage;
                var emit = this.emit;
                var sign_up = (this.model.type == 'requestor' ? 'sign_up_requestor' : 'sign_up_realtor');

                function test(parent, type) {
                    for (var property in parent) {
                        if (parent.hasOwnProperty(property)) {
                            var attribute = parent[property]
                            if ((attribute == '' || attribute === undefined || attribute == {}) && (property != 'priority' && property != 'policy')) {                                
                                if (type == 'realtor') {
                                    if (!['trade', 'site'].includes(property)) {
                                        return true;
                                    }
                                } else {
                                    if (!['location', 'place', 'trade', 'site'].includes(property)) {
                                        return true;
                                    }
                                }
                            }
                            if (typeof attribute === 'object' && attribute !== null) {
                                if (test(attribute, type)) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false
                }                

                this.alert = test(this.model, this.model.type);

                gtag.config('UA-158667515-1', {
                    'custom_map': {
                        'dimension1': 'try_number',
                        'dimension2': 'try_success',
                        'dimension3': 'user_type',
                    }
                });
                if (this.alert) {
                    messageAlert('warning.register.all');
                } else if (!this.model.policy) {
                    messageAlert('warning.register.policy');
                } else if (!this.passwordMatch) {
                    messageAlert('warning.register.password');
                } else {
                    $.ajax({
                        url: 'https://api.immobl.com/register' + (login ? '?login=true' : ''),
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        contentType: 'application/json;charset=UTF-8',
                        type: 'POST',
                        data: JSON.stringify({ data: this.model }),
                        success: function (response) {
                            if (response.success) {
                                if (login) {
                                    updateStorage(response.user_data);
                                    emit('loggedIn');
                                } else {
                                    store.state.alert.message = response.message;
                                    store.state.alert.new = true;
                                    store.state.alert.type = 'success';
                                }
                                
                                gtag.event(sign_up, { 
                                    'method': source, 
                                    'try_number': submitTry, 
                                    'try_success': true, 
                                    'event_callback': function () {
                                        if (!login) {
                                            router.push('login');
                                        }
                                    }
                                });
                            } else {
                                gtag.event('register_try', { 'try_number': submitTry, 'try_success': false, 'user_type': user_type })
                                messageAlert(response.message);                                
                            }
                        },
                        //error: function (err) {
                        //    //console.log(err);
                        //}
                    });
                }
                gtag.event('register_try', { 'try_number': submitTry, 'try_success': false, 'user_type': user_type })
                submitTry++;
            },
            //async initGoogleMaps() {
            //    if (this.source == 'modal') { // Makes the Google Maps Autocomplete container append to the input when on modal.
            //        var addressInputElement = $('#autocomplete');
            //        addressInputElement.on('focus', function () {
            //            var pacContainer = $('.pac-container');
            //            $(addressInputElement.parent()).append(pacContainer);
            //        })
            //    }
            //    var updateInput = this.updateInput;
            //    const google = await gmapsInit();
            //    const input = this.$refs.Autocomplete;
            //    const autocomplete = new google.maps.places.Autocomplete(
            //        input, { types: ['(cities)'], componentRestrictions: { country: 'br' } });
            //    autocomplete.setFields(['geometry']);
            //    autocomplete.addListener('place_changed', function () {
            //        const place = autocomplete.getPlace();
            //        if (!place.geometry) {
            //            input.value = '';
            //        } else {
            //            updateInput('city', 'place', place.geometry.location.toString());
            //            updateInput('city', 'location', input.value);
            //            input.placeholder = input.value;
            //            input.value = '';
            //            input.classList.add('autocompletePlaceholder');
            //        }
            //    });
            //}
        },
        //async mounted() {
        //    //this.initGoogleMaps()
        //}
    };
    $('input').on('keyup keypress', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });
</script>
<style scoped>
    .parent.vue-tel-input {
    }

    .autocompletePlaceholder::-webkit-input-placeholder {
        color: #8898aa
    }

    div /deep/ label {
        display:unset;
    }
</style>