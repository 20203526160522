<template>
    <section class="text-left">
        <p>Esta Política de Privacidade descreve nossas políticas e procedimentos para a coleta, uso e divulgação de suas informações quando Você usa Nosso Serviço e informa sobre Seus direitos de privacidade e como a lei o protege, além de detalhar Nossas responsabilidades e direitos.</p>
        <p>Utilizamos os Seus Dados Pessoais para fornecer e melhorar Nosso Serviço. Ao usar Nosso Serviço, Você concorda que suas informações sejam coletadas e usadas de acordo com esta Política de Privacidade.</p>
        <h2>Interpretação e definições</h2>
        <h3>Interpretação</h3>
        <p>As palavras em que a letra inicial é maiúscula têm significados definidos nas seguintes condições. As seguintes definições terão o mesmo significado, independentemente de aparecerem no singular ou no plural.</p>
        <h3>Definições</h3>
        <p>Para o propósito desta Política de Privacidade:</p>
        <ul>
            <li>
                <p><strong>Você</strong> significa o indivíduo que acessa ou usa o Serviço, ou a empresa ou outra entidade legal em nome da qual esse indivíduo está acessando ou usando o Serviço, conforme aplicável.</p>
            </li>
            <li>
                <p><strong>A Companhia</strong> (também referida como "Empresa" ou "Nós" neste Contrato) refere-se a Immobl.</p>
            </li>
            <li>
                <p><strong>Afiliada</strong> significa uma entidade que controla, é controlada por ou está sob controle comum com uma parte, em que "controle" significa a propriedade de 50% ou mais das ações, participações societárias ou outros valores mobiliários com direito a voto para eleição de conselheiros ou outra autoridade administrativa.</p>
            </li>
            <li>
                <p><strong>Conta</strong> significa uma conta exclusiva criada para Você acessar Nosso Serviço ou partes de Nosso Serviço.</p>
            </li>
            <li>
                <p><strong>Solicitante</strong> refere-se a um dos dois tipos de conta que é possível criar para acessar Nosso Serviço. Ao se cadastrar como Solicitante, Você pode usar Nosso Serviço para solicitar ofertas de imóveis com as características que previamente escolher.</p>
            </li>
            <li>
                <p><strong>Corretor Imobiliário</strong> (também referido como "Corretor" ou "Corretor de Imóveis") refere-se a um dos dois tipos de conta que é possível criar para acessar Nosso Serviço. Ao se cadastrar como Corretor Imobiliário, Você pode usar Nosso Serviço para responder a solicitações de imóveis de Solicitantes com ofertas.</p>
            </li>
            <li>
                <p><strong>Usuário</strong> refere-se à pessoa física ou jurídica que tenha criado uma Conta.</p>
            </li>
            <li>
                <p><strong>Site</strong> refere-se à plataforma online Immobl, acessível em <a href="https://www.immobl.com" target="_blank">https://www.immobl.com</a>.</p>
            </li>
            <li>
                <p><strong>Serviço</strong> refere-se ao Site.</p>
            </li>
            <li>
                <p><strong>Solicitação</strong> refere-se à solicitação customizada de imóvel criada pelo Solicitante no Serviço, disponível para Corretores.</p>
            </li>
            <li>
                <p><strong>Oferta</strong> (támbem referida como "Réplica" ou "Resposta") refere-se à oferta de imóvel enviada pelo Corretor para uma Solicitação disponível no Serviço.</p>
            </li>
            <li>
                <p><strong>País</strong> refere-se ao Brasil.</p>
            </li>
            <li>
                <p><strong>Provedor de Serviço</strong> significa qualquer pessoa singular ou coletiva que processa os dados em nome da Empresa. Refere-se a empresas de terceiros ou indivíduos empregados pela Empresa para facilitar o Serviço, prestar o Serviço em nome da Empresa, executar serviços relacionados ao Serviço ou ajudar a Empresa a analisar como o Serviço é usado.</p>
            </li>
            <li>
                <p><strong>Serviço de Mídia Social de Terceiros</strong> refere-se a qualquer site ou site de rede social através do qual um Usuário possa fazer login ou criar uma conta para usar o Serviço.</p>
            </li>
            <li>
                <p><strong>Dados Pessoais</strong> são quaisquer informações relacionadas a um indivíduo identificado ou identificável.</p>
            </li>
            <li>
                <p><strong>Cookies</strong> são pequenos arquivos que são colocados no Seu computador, dispositivo móvel ou qualquer outro dispositivo por um Site, contendo os detalhes do Seu histórico de navegação nesse Site entre Seus diversos usos.</p>
            </li>
            <li>
                <p><strong>Dispositivo</strong> significa qualquer dispositivo que possa acessar o Serviço, como um computador, telefone celular ou tablet digital.</p>
            </li>
            <li>
                <p><strong>Dados de Uso</strong> referem-se aos dados coletados automaticamente, gerados pelo uso do Serviço ou da própria infraestrutura do Serviço (por exemplo, a duração de uma visita à página).</p>
            </li>
        </ul>
        <h2>Coletando e usando Seus Dados Pessoais</h2>
        <h3>Tipos de coleta de dados</h3>
        <h4>Dados Pessoais</h4>
        <p>Ao usar o Nosso Serviço, podemos solicitar que Você nos forneça certas informações de identificação pessoal que podem ser usadas para entrar em contato ou identificá-lo. Informações de identificação pessoal podem incluir, mas não estão limitadas a</p>
        <ul>
            <li>
                <p>E-mail</p>
            </li>
            <li>
                <p>Nome e sobrenome</p>
            </li>
            <li>
                <p>Número de telefone</p>
            </li>
            <li>
                <p>Dados de Uso</p>
            </li>
        </ul>
        <h4>Dados de Uso</h4>
        <p>Dados de Uso são coletados automaticamente ao usar o Serviço.</p>
        <p>Os Dados de Uso podem incluir informações como o endereço de Protocolo da Internet do Seu dispositivo (por exemplo, endereço IP), tipo de navegador, versão do navegador, as páginas do Nosso Serviço que Você visita, a hora e a data da Sua visita, o tempo gasto nessas páginas, identificadores únicos de dispositivo e outros dados de diagnóstico.</p>
        <p>Quando Você acessa o Serviço por ou através de um dispositivo móvel, podemos coletar determinadas informações automaticamente, incluindo, entre outras, o tipo de dispositivo móvel que Você usa, o ID exclusivo do Seu dispositivo móvel, o endereço IP do Seu dispositivo móvel, sistema operacional do Seu dispositivo móvel, o tipo de navegador da Internet móvel que Você usa, identificadores exclusivos de dispositivo e outros dados de diagnóstico.</p>
        <p>Também podemos coletar informações que o Seu navegador envia sempre que Você visita Nosso Serviço ou quando acessa o Serviço por ou através de um dispositivo móvel.</p>
        <h4>Identificação no Google Analytics</h4>
        <p>Seus dados de cadastro estão associados a uma chave de identificação exclusiva (ID) nos Nossos sistemas. Optamos por usar junto ao Google Analytics a opção de rastrear Usuários com base nessa ID (funcionalidade por eles chamada de User-ID), com o único propósito de otimizar a análise do comportamento dos Usuários em Nosso Serviço. Isso não significa que podemos, Nós ou o Google, associar os comportamentos dos Usuários com quaisquer Dados Pessoais, visto que as IDs são mantidas em sigilo pelo Google, mas significa que podemos associar dados como cliques e visualizações de página a um único Usuário (não identificável).</p>
        <h4>Tecnologias de rastreamento e Cookies</h4>
        <p>Utilizamos Cookies e tecnologias de rastreamento similares para rastrear a atividade em Nosso Serviço e armazenar determinadas informações. As tecnologias de rastreamento usadas são beacons, tags e scripts para coletar e rastrear informações e melhorar e analisar Nosso Serviço.</p>
        <p>Você pode instruir Seu navegador a recusar todos os Cookies ou a indicar quando um Cookie está sendo enviado. No entanto, se Você não aceitar os Cookies, poderá não conseguir usar algumas partes do Nosso Serviço.</p>
        <p>Os Cookies podem ser "Persistentes" ou de "Sessão". Os Cookies persistentes permanecem no Seu computador pessoal ou dispositivo móvel quando Você fica offline, enquanto os Cookies de sessão são excluidos assim que Você fecha o navegador.</p>
        <p>Utilizamos Cookies de sessão e persistentes para os fins descritos abaixo.</p>
        <ul>
            <li>
                <p><strong>Cookies necessários/essenciais</strong></p>
                <p>Cookie de sessão, administrado por nós</p>
                <p>Esses Cookies permitem lembrar as escolhas que Você faz ao usar o Site, como lembrar os detalhes de login ou a preferência de idioma. O objetivo desses Cookies é fornecer a Você uma experiência mais pessoal e evitar que Você precise reinserir suas preferências sempre que usar o Site.</p>
            </li>
            <li>
                <p><strong>Cookies de funcionalidade</strong></p>
                <p>Cookie persistente, administrado por nós</p>
                <p>Esses Cookies permitem lembrar as escolhas que Você faz ao usar o Site, como lembrar os detalhes de login ou a preferência de idioma. O objetivo desses Cookies é fornecer a Você uma experiência mais pessoal e evitar que Você precise reinserir suas preferências sempre que usar o Site.</p>
            </li>
        </ul>
        <h3>Uso de Seus Dados Pessoais</h3>
        <p>A companhia pode usar Seus Dados Pessoais para os seguintes propósitos.</p>
        <ul>
            <li><strong>Para prover e manter Nosso Serviço</strong>, inclusive para monitorar o uso de Nosso Serviço.</li>
            <li><strong>Para gerenciar Sua conta:</strong> para gerenciar Seu registro como Usuário do Serviço. Os Dados Pessoais que Você fornece podem dar acesso a diferentes funcionalidades do Serviço que estão disponíveis para Você como Usuário registrado.</li>
            <li><strong>Para a execução de um contrato:</strong>  o desenvolvimento, conformidade e execução do contrato de compra dos produtos, itens ou serviços que Você comprou ou de qualquer outro contrato conosco através do Serviço.</li>
            <li><strong>Para entrar em contato com Você:</strong> Para entrar em contato com Você por e-mail, telefone, SMS ou outras formas equivalentes de comunicação eletrônica, como notificações por push de um aplicativo móvel sobre atualizações ou comunicações informativas relacionadas às funcionalidades, produtos ou serviços contratados, incluindo as atualizações de segurança, quando necessário ou razoável para Sua implementação.</li>
            <li><strong>Para fornecer a Você</strong> notícias, ofertas especiais e informações gerais sobre outros produtos, serviços e eventos que oferecemos semelhantes aos que Você já comprou ou consultou.</li>
            <li><strong>Para gerenciar Seus pedidos:</strong> Para atender e gerenciar Seus pedidos para Nós.</li>
        </ul>
        <p>Podemos compartilhar suas informações pessoais nas seguintes situações:</p>
        <ul>
            <li><strong>Com Provedores de Serviços:</strong> podemos compartilhar suas informações pessoais com Provedores de Serviços para monitorar e analisar o uso de Nosso Serviço.</li>
            <li><strong>Para transferências comerciais:</strong> Podemos compartilhar ou transferir Suas informações pessoais durante negociações de qualquer fusão, venda de ativos da Empresa, financiamento ou aquisição de todo ou parte de Nossos negócios para outra empresa.</li>
            <li><strong>Com Afiliados:</strong> podemos compartilhar suas informações com Nossos Afiliados; nesse caso, exigiremos que esses Afiliados honrem esta Política de Privacidade. As Afiliadas incluem Nossa controladora e quaisquer outras subsidiárias, parceiros de joint venture ou outras empresas que controlamos ou que estão sob controle comum conosco.</li>
            <li><strong>Com parceiros de negócio:</strong> podemos compartilhar suas informações com Nossos parceiros de negócios para oferecer a Você determinados produtos, serviços ou promoções.</li>
            <li><strong>Com outros Usuários:</strong> quando Você compartilha informações pessoais ou interage nas áreas públicas com outros Usuários, essas informações podem ser visualizadas por todos os Usuários e distribuídas publicamente. Se Você interagir com outros Usuários ou se registrar por meio de um Serviço de mídia social de terceiros, Seus contatos no Serviço de mídia social de terceiros poderão ver Seu nome, perfil, fotos e descrição de Sua atividade. Da mesma forma, outros Usuários poderão visualizar descrições de Sua atividade e visualizar Seu perfil.</li>
            <li><strong>Com Corretores Imobiliários:</strong> Nosso Serviço tem como principal objetivo conectar de forma inteligente, fácil e objetiva pessoas que buscam imóveis de todo tipo e Corretores Imobiliários. Se Você se cadastrar em Nosso Serviço como Solicitante, está de acordo com que Seus Dados Pessoais, incluindo e-mail e número de telefone, possam ser compartilhados com Usuários cadastrados como Corretores. Esse compartilhamento de dados será feito somente com Corretores cujas ofertas Você escolher "contatar", isto é, demonstrar interesse de ser contatado para saber mais sobre essas ofertas.</li>
            <li><strong>Com qualquer pessoa ou organização:</strong> Se julgarmos pertinente, podemos usar para fins de marketing qualquer feedback (comentários, depoimentos etc.) que Você nos envie de forma deliberada. Podemos associá-lo ao Seu nome, à Sua foto de perfil e à Sua Conta.</li>
        </ul>
        <h3>Retenção de Seus Dados Pessoais</h3>
        <p>A Empresa reterá Seus Dados Pessoais apenas pelo tempo necessário para os fins estabelecidos nesta Política de Privacidade. Reteremos e usaremos Seus Dados Pessoais na medida do necessário para cumprir nossas obrigações legais (por exemplo, se for necessário reter Seus dados para cumprir as leis aplicáveis), resolver disputas e aplicar Nossos acordos e políticas legais.</p>
        <p>A Empresa também reterá Dados de Uso para fins de análise interna. Os Dados de Uso geralmente são retidos por um período mais curto, exceto quando esses dados são usados para reforçar a segurança ou melhorar a funcionalidade do Nosso Serviço, ou somos legalmente obrigados a reter esses dados por períodos mais longos.</p>
        <h3>Transferência de Seus Dados Pessoais</h3>
        <p>Suas informações, incluindo Dados Pessoais, são processadas nos escritórios operacionais da empresa e em qualquer outro local em que as partes envolvidas no processamento estejam localizadas. Isso significa que essas informações podem ser transferidas para – e mantidas em – computadores localizados fora do Seu estado, província, país ou outra jurisdição governamental em que as leis de proteção de dados possam diferir daquelas da Sua jurisdição.</p>
        <p>Seu consentimento para esta Política de Privacidade, seguido de Seu envio de tais informações, representa automaticamente Seu acordo com essa transferência.</p>
        <p>Nós tomaremos todas as medidas razoavelmente necessárias para garantir que Seus dados sejam tratados com segurança e de acordo com esta Política de Privacidade e que nenhuma transferência de Seus Dados Pessoais ocorra para uma organização ou país, a menos que haja controles adequados, incluindo a segurança de Seus dados e outras informações pessoais.</p>
        <h3>Divulgação de Seus Dados Pessoais</h3>
        <h4>Transações comerciais</h4>
        <p>Se a Empresa estiver envolvida em uma fusão, aquisição ou venda de ativos, Seus Dados Pessoais poderão ser transferidos. Enviaremos um aviso antes que Seus Dados Pessoais sejam transferidos e se tornem sujeitos a uma Política de Privacidade diferente.</p>
        <h4>Aplicação da lei</h4>
        <p>Sob certas circunstâncias, Nós podemos ser obrigados a divulgar Seus Dados Pessoais, se exigido por lei ou em resposta a solicitações válidas de autoridades públicas (por exemplo, um tribunal ou uma agência governamental).</p>
        <h4>Outros requisitos legais</h4>
        <p>A Empresa pode divulgar Seus Dados Pessoais de boa fé, acreditando que tal ação é necessária para</p>
        <ul>
            <li>Cumprir uma obrigação legal</li>
            <li>Proteger e defender os direitos ou propriedades da Empresa</li>
            <li>Impedir ou investigar possíveis irregularidades relacionadas ao Serviço</li>
            <li>Proteger a segurança pessoal dos Usuários do Serviço ou do público</li>
            <li>Proteger contra responsabilidade legal</li>
        </ul>
        <h3>Segurança de Seus Dados Pessoais</h3>
        <p>A segurança dos Seus Dados Pessoais é importante para Nós, mas lembre-se de que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro. Embora Nos esforcemos para usar meios comercialmente aceitáveis para proteger Seus Dados Pessoais, não podemos garantir Sua segurança absoluta.</p>
        <h2>Direitos e responsabilidades</h2>
        <p>Nosso Serviço tem o intuito de facilitar a venda e a locação de todo tipo de imóvel, criando um canal inovador de conexão entre ambas as partes, Solicitante e Corretor. Portanto, Nós somos um facilitador de negócios imobiliários, mas apenas no que diz respeito ao contato das partes. A negociação ocorre fora do Serviço. Não Nos responsabilizamos por qualquer problema que possa ocorrer na negociação de imóveis cujo contato das partes tenha se iniciado em Nosso Serviço.</p>
        <p>Além disso, não Nos responsabilizamos pela</p>
        <ul>
            <li>verificação de identidade dos Usuários</li>
            <li>veracidade das Ofertas</li>
            <li>veracidade das Solicitações</li>
        </ul>
        <p>Nós temos o direito de encerrar Contas quando entendermos que apresentam qualquer ameaça a outros Usuários, com ou sem avisos e explicações.</p>
        <p>Temos também o direito de anular qualquer atividade de Usuários no Serviço se entendermos que apresentam risco a outros Usuários.</p>
        <h2>Links para outros Sites</h2>
        <p>Nosso Serviço pode conter links para outros Sites que não são operados por Nós. Se Você clicar em um link de terceiros, será direcionado para o Site desse terceiro. Aconselhamos veementemente que analise a Política de Privacidade de todos os Sites que visitar.</p>
        <p>Não temos controle e não assumimos nenhuma responsabilidade pelo conteúdo, políticas ou práticas de privacidade de Sites ou serviços de terceiros.</p>
        <h2>Alterações nesta Política de Privacidade</h2>
        <p>Podemos atualizar Nossa Política de Privacidade periodicamente. Nós o notificaremos de quaisquer alterações.</p>
        <p>Avisaremos Você por e-mail e/ou um aviso em destaque em Nosso Serviço, antes que a alteração entre em vigor e atualize a data "Última atualização" na parte superior desta Política de Privacidade.</p>
        <p>É aconselhável que Você reveja esta Política de Privacidade periodicamente para quaisquer alterações. As alterações a esta Política de Privacidade são efetivas quando publicadas nesta página.</p>
        <h2>Contate-Nos</h2>
        <p>Se Você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato Conosco pelo e-mail</p>
        <ul>
            <li>contact@immobl.com</li>
        </ul>
    </section>
</template>
<script>
export default {
  name: "privacy-policy",
};
</script>

<style scoped>
    h1, h2, h3, h4, h5, h6 {
        text-align: left;
    }
</style>